import orange from "./orange-circle.png";
import transparent from "./transparent-circle.png";
import styled from "@emotion/styled";
import "./App.css";
import React from "react";
import TextTransition, { presets } from "react-text-transition";
import Integrations from "./Integrations";
import ProductPic from "./product.png";

const H1 = styled.h1`
  color: #101c36;
  font-size: 2.5em;
  margin-bottom: 20px;
  margin-top: 0px;
`;

const Span = styled.span`
  color: #20376a;
  display: inline-block;
  background-color: #d8e0f3;
  padding: 0 20px;
  border-radius: 10px;
`;

const D = styled.div`
  color: #3d4554;
  font-size: 0.85em;
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
  justify-content: center;
`;

const Wrapper = styled.div`
  text-align: center;
  background-color: #fcf8f5;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  justify-content: center; /* Add this line */
  padding-bottom: 4rem; /* Add this line */
  overflow: hidden;
`;

const ProductExample = styled.div`
  width: 100%;
  flex-grow: 1;
  height: 500px;
  background-image: url(${ProductPic});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 7em;
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Demo = styled.div`
  background-color: #20376a;
  width: 10em;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.5em;
  border-radius: 200px;
  font-size: 0.85em;
  color: #fff;
  text-align: center;
  cursor: pointer;
`;

const Footer = () => {
  return (
    <footer>
      <a href="mailto:alfred@askmerlin.ai">Contact</a>
    </footer>
  );
};

function App() {
  const bubbleData = [
    { left: 15, bottom: 2, width: 80, src: transparent },
    { left: 80, bottom: 40, width: 110, src: transparent },
    { left: 10, bottom: 82, width: 64, src: orange },
    { left: 20, bottom: 80, width: 50, src: orange },
    { left: 10, bottom: 79, width: 120, src: transparent },
    { left: 85, bottom: 10, width: 120, src: orange },
    { left: 80, bottom: 10, width: 80, src: transparent },
    { left: 70, bottom: 78, width: 100, src: orange },
    { left: 70, bottom: 78, width: 35, src: transparent },
  ];
  const departements = ["Sales team.", "Legal team.", "HR team.", "IT team."];

  const [index, setIndex] = React.useState(0);
  const [randomStart, setRandomStart] = React.useState(
    bubbleData.map(() => Math.random() * 3 + 5)
  );
  React.useEffect(() => {
    const intervalId = setInterval(
      () => setIndex((index) => index + 1),
      2000 // every 2 seconds
    );
    return () => clearTimeout(intervalId);
  }, []);

  return (
    <div>
      <Wrapper>
        <header className="App-header">
          <div>Merlin AI</div>
        </header>
        <MainContainer>
          <TextContainer>
            <H1
              style={{
                marginBottom: 0,
              }}
            >
              Legal Work
            </H1>
            <H1
              style={{
                marginTop: 0,
              }}
            >
              <Span>reimagined</Span>
            </H1>
            <D>
              <div className="nowrap">
                Understand contracts in seconds.
              </div>
            </D>
          {/*<ProductExample />*/}
          <div className="product">
            <img src={ProductPic} style={{ width: "100%" }} />
          </div>
          </TextContainer>
        </MainContainer>
      </Wrapper>
      <Wrapper style={{ minHeight: "100px" }}>
        <Demo
          onClick={() =>
            window.open("https://calendly.com/merlinai/legal-demo", "_blank")
          }
        >
          Book a demo
        </Demo>
      </Wrapper>
    </div>
  );
}

export default App;
