import orange from "./orange-circle.png";
import transparent from "./transparent-circle.png";
import styled from "@emotion/styled";
import "./App.css";
import React from "react";
import TextTransition, { presets } from "react-text-transition";
import slack from "./icons/slack.svg";
import googleDrive from "./icons/google-drive.svg";
import outlook from "./icons/outlook.svg";
import msTeams from "./icons/ms-teams.svg";
import oneDrive from "./icons/onedrive.svg";
import jira from "./icons/jira.svg";
import azure from "./icons/azure.svg";
import box from "./icons/box.svg";
import confluence from "./icons/confluence.svg";
import datadog from "./icons/datadog.svg";
import figma from "./icons/figma.svg";
import github from "./icons/github.svg";
import gong from "./icons/gong.svg";
import jenkins from "./icons/jenkins.svg";
import looker from "./icons/looker.svg";
import sharepoint from "./icons/sharepoint.svg";
import trello from "./icons/trello.svg";
import zendesk from "./icons/zendesk.svg";

const H1 = styled.h1`
  color: #101c36;
  font-size: 2em;
  margin-bottom: 20px;
  margin-top: 0px;
`;
const Span = styled.span`
  color: #ff6501;
  display: inline-block;
  background-color: #fae8dc;
  padding: 0 20px;
  border-radius: 10px;
`;

const Capsule = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 130px;
  height: 35px;
  background-color: #fff;
  color: #101c36;
  filter: drop-shadow(rgba(0, 0, 0, 0.1) 0px 4px 6px);
  border-radius: 25px;
  position: relative;
  animation: moveRightFade ${({ speed }) => 90 + speed}s linear infinite;
  animation-delay: ${({ index }) => index * -1 - 12}s;
  @keyframes moveRightFade {
    0% {
      transform: translateX(-300%);
    }
    100% {
      transform: translateX(300%);
    }
  }
`;

const CapsuleWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Mask = styled.div`
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  background: linear-gradient(
    90deg,
    rgba(252, 248, 245, 1) 5%,
    rgba(252, 248, 245, 0) 15%,
    rgba(252, 248, 245, 0) 85%,
    rgba(252, 248, 245, 1) 95%
  );
`;

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  padding: 0px 20px;
  display: flex;
  align-items: center;
`;
const Gap = styled.div`
  width: 5px;
`;
const CapsuleContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  height: 35px;
  margin-top: 10px;
  width: 100%;
  flex-wrap: nowrap;
`;

const MegaWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const LeftWrapper = styled.div`
  padding: 30px;
  min-width: 50%;
  width: 50%;
`;

const Icon = styled.div`
  width: 15px;
  height: 15px;
  margin-right: 5px;
  background-color: #fff;
  background-image: url(${({ icon }) => icon});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
`;

const InsideWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const D = styled.div`
  color: #3d4554;
  font-size: 1em;
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
  justify-content: left;
`;

function Integrations() {
  const integration = ["Slack", "G Suite", "Outlook", "Salesforce", "Hubspot"];

  // const icons = [
  //   { name: "Jira", icon: jira },
  //   { name: "Azure", icon: azure },
  //   { name: "Box", icon: box },
  //   { name: "Confluence", icon: confluence },
  //   { name: "Datadog", icon: datadog },
  //   { name: "Figma", icon: figma },
  //   { name: "Slack", icon: slack },
  //   { name: "Google Drive", icon: googleDrive },
  //   { name: "Outlook", icon: outlook },
  //   { name: "MS Teams", icon: msTeams },
  //   { name: "OneDrive", icon: oneDrive },
  //   { name: "Github", icon: github },
  //   { name: "Gong", icon: gong },
  //   { name: "Jenkins", icon: jenkins },
  //   { name: "Looker", icon: looker },
  //   { name: "Sharepoint", icon: sharepoint },
  //   { name: "Trello", icon: trello },
  //   { name: "Zendesk", icon: zendesk },
  // ];
  const icons = [
    { name: "Jira", icon: jira },
    { name: "Azure", icon: azure },
    { name: "Box", icon: box },
    { name: "Confluence", icon: confluence },
    { name: "Datadog", icon: datadog },
    { name: "Figma", icon: figma },
    { name: "Slack", icon: slack },
    { name: "Box", icon: box },
  ];
  const icons2 = [
    { name: "Google Drive", icon: googleDrive },
    { name: "Outlook", icon: outlook },
    { name: "MS Teams", icon: msTeams },
    { name: "OneDrive", icon: oneDrive },
    { name: "Github", icon: github },
    { name: "Gong", icon: gong },
    { name: "MS Teams", icon: msTeams },
  ];
  const icons3 = [
    { name: "Jenkins", icon: jenkins },
    { name: "Looker", icon: looker },
    { name: "Sharepoint", icon: sharepoint },
    { name: "Zendesk", icon: zendesk },
    { name: "OneDrive", icon: oneDrive },
    { name: "Trello", icon: trello },
    { name: "Outlook", icon: outlook },
  ];

  return (
    <MegaWrapper>
      <Wrapper>
        <div>
          <Mask />
          <CapsuleContainer style={{ marginTop: "20px" }}>
            {icons.map((item, index) => (
              <CapsuleWrapper key={index}>
                <Capsule index={index} speed={0}>
                  {" "}
                  <Icon icon={item.icon} /> {item.name}
                </Capsule>
              </CapsuleWrapper>
            ))}
          </CapsuleContainer>
          <CapsuleContainer>
            {icons2.map((item, index) => (
              <CapsuleWrapper key={index}>
                <Capsule index={index} speed={2}>
                  {" "}
                  <Icon icon={item.icon} /> {item.name}
                </Capsule>
              </CapsuleWrapper>
            ))}
          </CapsuleContainer>
          <CapsuleContainer>
            {icons3.map((item, index) => (
              <CapsuleWrapper key={index}>
                <Capsule index={index} speed={-5}>
                  {" "}
                  <Icon icon={item.icon} /> {item.name}
                </Capsule>
              </CapsuleWrapper>
            ))}
          </CapsuleContainer>
        </div>
      </Wrapper>
      <LeftWrapper>
        <H1
          style={{
            marginBottom: 0,
          }}
        >
          <Span>Seamlessly</Span>
        </H1>
        <H1
          style={{
            marginTop: 0,
          }}
        >
          integrate with your existing tools
        </H1>
        <H1
          style={{
            marginBottom: 0,
          }}
        ></H1>
        <D>
          Connect all your existing applications. Experience the power of your
          company’s collective knowledge all in one place.
        </D>
      </LeftWrapper>
    </MegaWrapper>
  );
}

export default Integrations;
